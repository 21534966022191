/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


  $(window).load(function(){
    if (Modernizr.videoautoplay) {
      // showOffAwesomeNewFeature();
      console.log('||||| can vid autoplay!');
      $('.logowrap-static').addClass('hidden');
      $('.logowrap').removeClass('hidden');
    } else {
      console.log('||||| can NOT vid autoplay!');
      $('.logowrap-static').removeClass('hidden');
      $('.logowrap').addClass('hidden');
    }
  });



  $(document).ready(function(){
    // -- RUN MODERNIZR DETECTION FOR VIDEO AUTOPLAY AND HIDE LOGO VIDEO IF IT IS NOT AVAILABLE ------------------


    // -- ADJUST INITIAL SCROLL ON SEARCH VIEWS ---------------------------------------------------------------
    if( $('body.search').length ){
      $(window).scrollTop( $("#epd-table").offset().top -130 );
    }





    // default_sortcol = $("#epd-table th").length - 1; // get last column
    default_sortcol = 2;
    default_sortorder = 0;




    // -- INITIALIZE THEAD TH SORTING PLUGIN -----------------------------------------------------------------
    if( $("th.edit").length ){
      sortcol = default_sortcol+1;
      $("#epd-table").tablesorter({
        headers: {
          0: { sorter: false }, // prevent edit column header from having a sort control
          2: { sorter: false }, // prevent droplet column header from having a sort control
        },
        sortList: [ [ sortcol , default_sortorder ] ],
        sortRestart : true,
      });
      console.log('has edit col :)');
      console.log( "sort column: " + sortcol );
    }else{
      sortcol = default_sortcol;
      $("#epd-table").tablesorter({
        headers: {
          1: { sorter: false }, // prevent droplet column header from having a sort control
        },
        sortList: [ [ default_sortcol , default_sortorder ] ],
        sortRestart : true,
      });
      console.log('no edit col :(');
      console.log( "sort column: " + sortcol );
    }

    $("#epd-table-mobile").tablesorter( { sortList: [[0,0]] } );


    // - - - - - - - - - - - - - - - - - - - safari check/exclusion
    var is_safari = navigator.userAgent.indexOf("Safari") > -1;
    if (!is_safari) {

      // -- INITIALIZE TABLE THEAD ELEMENT PINNING -----------------------------------------------------------------
      var $table = $('table.sticky');
      var $table_mobile = $('table.table-mobile-sticky');

      $table.floatThead({
        responsiveContainer: function($table){
          return $table.closest('.table-responsive-wrap');
        }
      });


      $table_mobile.floatThead({
        responsiveContainer: function($table_mobile){
          return $table_mobile.closest('.table-responsive-wrap');
        }
      });

    };
    // - - - - - - - - - - - - - - - - - - - end safari check/exclusion




  }); // END $(document).ready


})(jQuery); // Fully reference jQuery after this point.

